import React from "react"
import MissionMobile from "../assets/Frame 106.svg"
import Mission from "../assets/Frame 9.svg"
import VisionMobile from "../assets/Frame mobile.svg"
import Vision from "../assets/Frame 10.svg"

const Reason = () => {
  return (
    <div className="relative container mx-auto p-6 mt-4">
      {/* Title */}
      <p className="text-center mt-6 text-lg">WHY</p>

      <div className="mt-10 lg:mt-6 mb-8 ">
        <h2 className="text-4xl mt-2 mb-4 tracking-wide text-center font-bold">
          The Reason
        </h2>

        <p className="text-xl my-4 md:mb-8 lg:text-xl mx-auto ">
          Project management can be challenging for the average person or
          corporation. These challenges can sometimes lead to emotional impacts
          like frustration, mistrust, and a sense of loss of hope. Relying on
          alternative options such as family, friends, or partners can pose a
          high risk to project milestones and timelines and may require the
          sacrifice of personal convenience or important life-centered events.
        </p>
      </div>

      <div className="lg:flex lg:flex-row">
        {/* Mission */}
        <div className="lg:flex lg:flex-row m-2 ">
          <img className="mt-4  w-full lg:hidden" src={MissionMobile} alt="" />
          <img
            className="mt-4 lg:mt-0 lg:w-1/2 w-full hidden lg:block bg-contain"
            src={Mission}
            alt=""
          />

          <div className="border-gray border px-2">
            <h2 className="text-4xl mt-2 mb-6 tracking-wide text-center font-bold">
              The Mission
            </h2>
            <p className="text-xl text-gray-500 pl-3 md:text-left">
              We are dedicated to enabling customers to effortlessly manage
              their projects from anywhere in the world with peace of mind. Our
              innovative software solution integrates expertise and modern-day
              technology to enable efficient and effective remote project
              management, providing customers with the ability to make accurate
              and data-driven decisions.
            </p>
          </div>
        </div>

        {/* Vision */}
        <div className=" lg:flex lg:flex-row m-2">
          <img className="mt-4  w-full lg:hidden" src={VisionMobile} alt="" />
          <img
            className="mt-4 lg:mt-0 lg:w-1/2 w-full hidden lg:block h-auto bg-contain"
            src={Vision}
            alt=""
          />

          <div className="border-gray border px-2">
            <h2 className="text-4xl mt-2 mb-6 tracking-wide text-center font-bold">
              The Vision
            </h2>
            <p className="text-xl text-gray-500 pl-3 md:text-left">
              Our vision is to become the leading provider of project management
              solutions, empowering businesses of all sizes and individuals to
              achieve their goals efficiently and effectively. We are committed
              to operational excellence by continuously improving our processes
              and exploring new innovative ways to meet the evolving needs of
              our customers.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reason
