import React from "react"
import Logo from "../assets/SVP Logo-primary inversed.svg"
import {
  FaFacebookSquare,
  FaYoutube,
  FaInstagram,
  // FaLinkedin,
  FaGooglePlay,
  FaPhone,
  FaRegEnvelope,
} from "react-icons/fa"

const Footer = () => {
  return (
    <div className="bg-black text-white flex flex-col">
      <div className="px-4 md:px-20">
        <img src={Logo} alt="" className="w-44  mt-10" />
      </div>

      <div className="mx-auto pt-8 px-8 md:px-20 flex flex-col items-center md:flex-row md:space-y-0 md:justify-between md:items-start  md:mx-0">
        {/* Left Side */}
        <div className="md:w-1/2 md:mr-4">
          <p className="text-lg">
            Request a Demo of the SVP Project Management Platform to view all
            features and also get first-hand access to the power and control the
            platform offers.
          </p>

          <div className="flex flex-col my-10 md:flex-row md:w-2/3">
            <input
              placeholder="Your email"
              className="border border-white bg-black px-8 py-4 w-full "
            />
            <button className="mx-auto md:w-3/4 px-8 md:px-0 md:my-0 md:mx-4 py-4 font-bold text-black border-white border bg-white w-full my-4">
              Join Waitlist
            </button>
          </div>

          <p>
            Join the waitlist for our cutting-edge project management platform
            today and be among the first to experience the power of streamlined
            collaboration and optimized project workflows.
          </p>
        </div>

        {/* Right Side */}
        <div className="flex flex-col my-10 md:flex-row md:w-1/2 w-full">
          <div className="flex flex-row md:flex-col my-2 md:w-2/5 px-4 justify-between items-center">
            <a href="#about" className="text-white hover:text-[#BA8842] my-4 ">
              About
            </a>
            <a
              href="#services"
              className="text-white hover:text-[#BA8842]  my-4"
            >
              Services
            </a>
            <a href="#faqs" className="text-white hover:text-[#BA8842]  my-4">
              FAQs
            </a>
            <a
              href="#contact"
              className="text-white hover:text-[#BA8842]   my-4"
            >
              Contact
            </a>
          </div>

          <div className="flex flex-col md:w-3/5 px-4 my-2">
            <a
              className="text-white hover:text-[#BA8842]  my-4 my-4 flex bg-black"
              href="mailto:info@saharaviewpoint.com"
            >
              <FaRegEnvelope className="mr-2 my-auto text-xl" />{" "}
              info@saharaviewpoint.com
            </a>
            <a className="text-white hover:text-[#BA8842]  my-4 my-4 flex bg-black">
              <FaPhone
                className="mr-2 my-auto text-xl"
                href="tel:+2349121439919"
              />{" "}
              +234 912 143 9919
            </a>

            <div className="flex flex-col mt-2">
              <span className="">Download the SVP android app</span>
              <button
                href="#"
                className="md:mt-0 hover:text-[#BA8842] my-4 flex bg-white text-black py-3 px-3 font-bold md:w-2/5 items-center justify-center"
              >
                {" "}
                <FaGooglePlay className="mx-2 bg-white my-auto text-2xl hidden md:block" />{" "}
                Download{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between w-full px-8 ">
        <div>
          <p className="my-4">
            &copy; SaharaViewPoint 2024 . All Rights Reserved
          </p>
        </div>

        <div className="flex justify-between  my-4 px-16 ">
          {/* <a>
            <FaLinkedin size={30} className="mx-2 hover:text-[#BA8842]" />
          </a> */}

          <a
            href="https://www.facebook.com/SaharaViewPoint.n?mibextid=LQQJ4d"
            target="_blank"
          >
            <FaFacebookSquare size={30} className="mx-2 hover:text-[#BA8842]" />
          </a>

          <a
            href="https://www.instagram.com/saharaviewpoint?igsh=MWU4OTI1enY1YTI4OA=="
            target="_blank"
          >
            <FaInstagram size={30} className="mx-2 hover:text-[#BA8842]" />
          </a>

          <a href="https://www.youtube.com/@SaharaViewPoint" target="_blank">
            <FaYoutube size={30} className="mx-2 hover:text-[#BA8842]" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
