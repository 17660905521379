import React from 'react'

const Streamline = () => {
  return (
    <div className='mx-auto p-6 md:mx-20 mt-10'>
       <h2 className='text-4xl mt-2 mb-4 md:mb-14 tracking-wide text-center font-bold'>Streamline Your Workflow</h2>
    
       
       <div className='md:mx-20'>
           <p className='text-lg mb-10 md:text-xl'>
       See how we can make project management better and more reliable for you by watching our short video.
       We believe that our platform is the best solution for everyone who wants to take their project management to the next level.
       </p>
           <div className='md:aspect-w-16 md:aspect-h-9 aspect-video'>
       <iframe
    className='w-full mx-auto h-full'
        src="https://www.youtube.com/embed/LiwUfLQ60m8?si=BAacaqgTcBl_hY0m"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
       </div>
       </div>
    
    </div>
  )
}

export default Streamline
