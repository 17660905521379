import React from "react"
import { AnchorLink } from "react-anchor-link-smooth-scroll"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Navbar from "./components/Navbar"
import Sponsors from "./components/Sponsors"
import Mission from "./components/Mission"
import Reason from "./components/Reason"
import Footer from "./components/Footer"
import Contact from "./components/Contact"
import FAQs from "./components/FAQs"
import Services from "./components/Services"
import Streamline from "./components/Streamline"
import Story from "./components/Story"

function App() {
  return (
    <Router>
      <div className="App scroll-smooth">
        <Navbar />

        <Sponsors />
        <Mission />
        <Story />
        <Reason />
        <Services />
        <Streamline />
        <FAQs />
        <Contact />
        <Footer />
      </div>
    </Router>
  )
}

export default App
