import React from "react"
import Contactimage from "../assets/contact.svg"
import { useForm, ValidationError } from "@formspree/react"

const Contact = () => {
  const [state, handleSubmit] = useForm("xnqevkel")

  if (state.succeeded) {
    setTimeout(() => {
      alert("Message sent successfully!")
    }, 2000)
  }

  return (
    <div className="relative container mx-auto p-6 " id="contact">
      <h2 className="text-4xl mt-2 mb-4 md:mb-20 tracking-wide text-center font-bold">
        Contact Us
      </h2>

      <div className="md:flex flex-row">
        {/* Form */}
        <div className="md:w-1/2 md:h-full">
          <form className="flex flex-col w-full" onSubmit={handleSubmit}>
            <div className="my-4 md:w-4/5 md:items-center md:mx-auto">
              <p className="text-lg text-gray-500  items-center mb-6 md:items-start">
                Our Team would love to hear from you.
              </p>

              <div className="flex flex-col mb-4 md:my-6">
                <label htmlFor="name" className="font-semibold text-gray-700">
                  Full name:
                </label>
                <input
                  type="text"
                  className="border-2 outline-gray-700 py-2  focus:bg-indigo-100 "
                  id="name"
                  name="name"
                />
                <p className="text-xs text-red-500 hidden">
                  * Name is required
                </p>
              </div>

              <div className="flex flex-col my-4 md:my-6">
                <label htmlFor="email" className="font-semibold text-gray-700">
                  Email:
                </label>
                <input
                  type="email"
                  className="border-2  outline-gray-700 py-2  focus:bg-indigo-100"
                  id="email"
                  name="email"
                />
                <p className="text-xs text-red-500 hidden">
                  * Please enter a valid email
                </p>
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>

              <div className="flex flex-col my-4 md:my-6">
                <label
                  htmlFor="phoneNumber"
                  className="font-semibold text-gray-700"
                >
                  Phone Number:
                </label>
                <input
                  type="number"
                  className="border-2  outline-gray-700 py-2  focus:bg-indigo-100"
                  id="phoneNumber"
                  name="phoneNumber"
                />
                <p className="text-xs text-red-500 hidden">
                  * Please enter a valid email
                </p>
              </div>

              <div className="flex flex-col my-4 w-full md:my-6">
                <label htmlFor="" className="font-semibold text-gray-700 my-2">
                  Message:
                </label>
                <textarea
                  className="border-2 outline-gray-700 p-3 md:p-5  focus:bg-indigo-100"
                  id="message"
                  name="message"
                />
                <p className="text-xs text-red-500 hidden">
                  * Please enter a message
                </p>
              </div>

              <div className="my-4 w-full mx-0">
                <button
                  type="submit"
                  className="text-lg tracking-wide font-bold px-6 py-4 outline-none bg-black text-white w-full md:mt-4 transition duration-500 
                hover:bg-[#BA8842]"
                  disabled={state.submitting}
                >
                  Send
                </button>
              </div>
            </div>
          </form>
          {state.succeeded && <p>Message sent successfully!</p>}
        </div>

        {/* Image */}
        <div className="md:w-1/2 hidden md:block">
          <img src={Contactimage} alt="contact " className="" />
        </div>
      </div>
    </div>
  )
}

export default Contact
