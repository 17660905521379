import React from 'react'
import PacMan from '../assets/pacman.svg'
import Calendar from '../assets/calendar.svg'
import Clock from '../assets/time.svg'
import '../style.css'


function Story() {
  return (
    <div className=' bg-center  relative container mx-auto p-6 md:p-20 bg-mobile md:bg-desktop lg:lg-mobile md:bg-cover'>
    {/* Title */}
    <div className=' text-center mt-10 font-bold'>
        
        <h2 className='text-4xl mt-4 mb-2 tracking-wide items-center justify-center'>We’re changing the Landscape... Literally!</h2>
        
    </div>
  
    <div className='md:flex md:flex-row'>
        <div className='md:w-3/5 md:pb-4'>
            {/* mini title */}
            <div className='mt-10 md:px-10'>
          
                <div className='flex flex-col p-2 '>
                    <h2 className=' text-3xl leading-relaxed'>We are building project management solutions to empower businesses and individuals to achieve their goals.</h2>
                    <p className=' md:pr-10 mt-4 md:text-lg leading-[2]'>
                    A platform designed to instill trust and confidence in our customers, ensuring that they can rely on us for secure and reliable project management solutions.                    </p>
                </div>
            </div>
            
            
       
    </div>
    
   
        <div className='md:w-2/5 md:pb-3'>
            {/* Communicate */}
            <div className='mt-10'>
                <img className='my-4 md:my-1' src={Clock} alt=''/>
                <div className='flex flex-col p-2'>
                    <h2 className=' text-2xl'>Years of intense research</h2>
                    <p className=' text-gray-500 mt-4 '>
                    Our founders came up with a solution; this is your report manager, task scheduler and project manager streamlined into one platform.                    </p>
                </div>
            </div>
            
            {/* Collaborate */}
            <div className='mt-10 md:mt-5'>
                <img className='my-4 md:my-1' src={Calendar} alt=''/>
                <div className='flex flex-col p-2'>
                    <h2 className=' text-2xl'>Effective Remote Project Management</h2>
                    <p className=' text-gray-500 mt-4 '>
                    Take control of all your projects from anywhere in the world.                    </p>
                </div>
            </div>
        
            {/* Succeed */}
            <div className='mt-10  md:mt-5'>
                    <img className='my-4 md:my-1' src={PacMan} alt=''/>
                    <div className='flex flex-col p-2'>
                        <h2 className=' text-2xl'>Data-Driven Decisions</h2>
                        <p className=' text-gray-500 mt-4 '>
                        Make decisions based on accurate, verified reports from an expert team of project managers.                        </p>
                    </div>
                </div>
        </div>
        
       
    </div>
    </div>

  )
}

export default Story
