import React from "react"
import IPAD from "../assets/MacBook Pro Mockup Right View.svg"

const Services = () => {
  return (
    <div className="relative container mx-auto p-6 " id="services">
      {/* Title */}
      <p className="text-center mt-6  text-lg">WHAT WE OFFER</p>
      <h2 className="text-4xl mt-2 mb-4 md:mb-20 tracking-wide text-center font-bold">
        Services
      </h2>

      <div className="flex md:flex-row mx-auto flex-col relative container">
        <div className="flex flex-col md:w-2/5">
          <div className="hover:bg-[#BA8842] hover:text-white text-black p-6 my-4 transition duration-500 ">
            <h2 className="text-4xl mt-2 mb-4 tracking-wide  font-bold ">
              On-site Reporting
            </h2>
            <p className="text-xl mb-4 md:text-xl">
              We provide real-time visibility into your project's progress,
              allowing you to easily track key performance indicators (KPIs).
            </p>
          </div>

          <div className=" p-6 my-4 hover:bg-[#BA8842] hover:text-white transition duration-500 ">
            <h2 className="text-4xl mt-2 mb-4 tracking-wide  font-bold ">
              Dedicated Project Manager
            </h2>
            <p className="text-xl mb-4 md:text-xl">
              With our reporting capabilities, you can quickly identify
              bottlenecks, monitor overall project health.
            </p>
          </div>

          <div className=" p-6 my-4 hover:bg-[#BA8842] hover:text-white transition duration-500 ">
            <h2 className="text-4xl mt-2 mb-4 tracking-wide  font-bold ">
              Task Scheduling
            </h2>
            <p className="text-xl mb-4 md:text-xl">
              Easily plan and prioritize tasks, assign them to team members, and
              track progress in real-time.
            </p>
          </div>
        </div>

        {/* Image */}
        <div className="mx-auto md:w-3/5 md:my-10 md:mx-20">
          <img src={IPAD} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Services
