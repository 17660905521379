import React from "react"

const FAQs = () => {
  return (
    <div className="mx-auto p-6 md:mx-20 mt-10" id="faqs">
      {/* FAQ Heading */}
      <section id="faq">
        <div className="container mx-auto">
          <h2 className="mb-6 text-3xl font-semibold text-center md:text-4xl">
            Frequently Asked Questions
          </h2>
          <p className="max-w-lg px-6 mx-auto text-center text-gray-600">
            Here are some of our FAQs. If you have any other questions you'd
            like answered please feel free to email us.
          </p>
        </div>
      </section>

      {/* FAQ Accordion */}
      <section id="faq-accordion">
        {/* Main Container */}
        <div className="container mx-auto px-6 mb-22">
          {/* Accordion Container */}
          <div className=" m-8 w-full mx-auto overflow-hidden">
            {/* Tab 1 */}
            <div className="py-1 border-b outline-none group" tabIndex="1">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  What is Saharaviewpoint
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Saharaviewpoint is a comprehensive project management platform
                  designed to track the progress of construction projects,
                  reconstructions, roadworks, and other infrastructure
                  developments. It provides detailed insights into each stage of
                  the project, including materials used, timelines, and
                  milestones.
                </p>
              </div>
            </div>

            {/* Tab 2 */}
            <div className="py-1 border-b outline-none group" tabIndex="2">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  How does Saharaviewpoint benefit users?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Saharaviewpoints empowers users to closely monitor the
                  development of their projects in real-time. By offering
                  transparency and accessibility, users can make informed
                  decisions, address any issues promptly, and ensure projects
                  stay on track and within budget.
                </p>
              </div>
            </div>

            {/* Tab 3 */}
            <div className="py-1 border-b outline-none group" tabIndex="3">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  What features does Saharaviewpoints offer?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Saharaviewpoints offers features such as progress tracking,
                  material inventory, milestone scheduling, feedback submission,
                  and communication tools. Users can access detailed reports,
                  timelines, and analytics to gain a comprehensive understanding
                  of their projects.
                </p>
              </div>
            </div>

            {/* Tab 4  */}
            <div className="py-1 border-b outline-none group" tabIndex="4">
              {/* Tab Flex Container  */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  Can Saharaviewpoints be customized for specific projects?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content  */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Yes, Saharaviewpoints is highly customizable to suit the
                  unique needs of each project. Users can tailor the platform to
                  reflect project specifications, workflows, and requirements,
                  ensuring maximum efficiency and relevance.
                </p>
              </div>
            </div>

            {/* Tab 5 */}
            <div className="py-1 border-b outline-none group" tabIndex="5">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  How does Saharaviewpoint handle feedback from customers?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Saharaviewpoint facilitates seamless communication between
                  stakeholders by providing a feedback submission mechanism
                  directly within the platform. Customers can easily share their
                  comments, concerns, and suggestions, allowing for timely
                  adjustments and improvements.
                </p>
              </div>
            </div>

            {/* Tab 6 */}
            <div className="py-1 border-b outline-none group" tabIndex="6">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  Is Saharaviewpoint suitable for large-scale projects?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Yes, Saharaviewpoint is designed to accommodate projects of
                  all sizes, including large-scale infrastructure developments.
                  Its scalable infrastructure and robust features make it
                  suitable for managing complex projects with multiple
                  stakeholders and phases.
                </p>
              </div>
            </div>

            {/* Tab 7 */}
            <div className="py-1 border-b outline-none group" tabIndex="7">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  How secure is Saharaviewpoint?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Saharaviewpoint prioritizes data security and privacy,
                  implementing industry-standard encryption protocols and access
                  controls to safeguard sensitive information. Regular security
                  audits and updates ensure that the platform remains protected
                  against potential threats.
                </p>
              </div>
            </div>

            {/* Tab 8  */}
            <div className="py-1 border-b outline-none group" tabIndex="8">
              {/* Tab Flex Container  */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  How user-friendly is Saharaviewpoint?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content  */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Saharaviewpoint is designed with user-friendliness in mind,
                  featuring an intuitive interface and straightforward
                  navigation. Training and support resources are also available
                  to help users maximize their experience and proficiency with
                  the platform.
                </p>
              </div>
            </div>

            {/* Tab 9 */}
            <div className="py-1 border-b outline-none group" tabIndex="9">
              {/* Tab Flex Container */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  Can Saharaviewpoint integrate with other software or tools?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Yes, Saharaviewpoints supports integration with various
                  third-party software and tools commonly used in the
                  construction and project management industry. This includes
                  accounting software, scheduling tools, and document management
                  systems, among others.
                </p>
              </div>
            </div>

            {/* Tab 10  */}
            <div className="py-1 border-b outline-none group" tabIndex="10">
              {/* Tab Flex Container  */}
              <div className="flex items-center justify-between py-3 text-gray-500 transition duration-500 cursor-pointer group ease">
                {/* Tab Title */}
                <div className="transition duration-500 ease group-hover:text-[#BA8842]">
                  How can I get started with Saharaviewpoint?
                </div>
                {/* Arrow */}
                <div className="transition duration-500 ease group-focus:-rotate-180 group-focus:text-[#BA8842]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="12"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="3"
                      d="M1 1l8 8 8-8"
                    />
                  </svg>
                </div>
              </div>

              {/* Tab Inner Content  */}
              <div className="overflow-hidden transition duration-500 group-focus:max-h-screen max-h-0 ease">
                <p className="py-2 text-justify text-gray-400">
                  Getting started with Saharaviewpoints is simple. Contact our
                  team to schedule a demo and discuss how Saharaviewpoints can
                  be tailored to meet your specific project management needs.
                  We'll guide you through the setup process and provide ongoing
                  support to ensure a smooth experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default FAQs
