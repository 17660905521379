import React, { useState } from "react"
import Logo from "../assets/SVP Logo-Primary.svg"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import HeroImage from "../assets/iPad Pro 12.9-inch Space Gray Mockup.svg"
import { Link } from "react-anchor-link-smooth-scroll"

const Navbar = () => {
  const [nav, setNav] = useState(false)

  const handleNav = () => {
    setNav(!nav)
  }

  return (
    <div className="md:h-screen">
      <div className="relative container mx-auto py-6">
        {/* Nav Bar */}
        <div className="flex justify-between border-b-2 fixed z-10 top-0 bg-white w-full right-0">
          {/* Flex Container For Logo/Menu */}
          <div className="flex items-center space-x-20">
            {/* Logo */}
            <img src={Logo} alt="" className="w-54 my-auto" />
          </div>

          {/* Left Menu */}
          <div className="hidden space-x-12 font-bold lg:flex my-auto">
            <a href="#about" className="text-black hover:text-[#BA8842]">
              About
            </a>
            <a href="#services" className="text-black hover:text-[#BA8842]">
              Services
            </a>
            <a href="#faqs" className="text-black hover:text-[#BA8842]">
              FAQs
            </a>
            <a href="#contact" className="text-black hover:text-[#BA8842]">
              Contact
            </a>
          </div>

          {/* Right Buttons Menu  */}
          <div className="hidden items-center space-x-6 font-bold mr-2 md:flex">
            <a
              className="hover:bg-[#BA8842] hover:text-white hover:border-[#BA8842] px-6 py-3 font-bold text-black border-black border"
              href="#"
            >
              Login
            </a>
            <a
              className="hover:bg-[#BA8842] hover:text-white hover:border-[#BA8842] px-6 py-3 font-bold text-white border-black border bg-black"
              href="#"
            >
              Request a Demo
            </a>
          </div>

          {/* Menu Toggle Button */}
          <div
            onClick={handleNav}
            className="block md:hidden mr-3 my-auto cursor-pointer"
          >
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>
        </div>

        {/* Mobile Menu */}
        <ul
          className={`${
            nav
              ? "fixed left-0 top-0 w-[90%] h-full border-r border-r-gray-500 bg-white ease-in-out duration-500 items-center flex flex-col z-20 "
              : "ease-in-out duration-500 fixed left-[-100%]"
          }`}
        >
          <img src={Logo} alt="Logo" />
          <li className="p-4 hover:border-[#BA8842] font-semibold">
            <a href="#about">About</a>
          </li>
          <li className="p-4 hover:border-[#BA8842] font-semibold">
            <a href="#services">Services</a>
          </li>
          <li className="p-4 hover:border-[#BA8842] font-semibold">
            <a href="#faqs">FAQs</a>
          </li>
          <li className="p-4 hover:border-[#BA8842] font-semibold">
            <a href="#contacts">Contact</a>
          </li>
        </ul>
      </div>

      {/* Hero Section */}
      <section
        id="hero"
        className="container flex flex-col mx-auto p-6 md:flex-row md:mt-10 transition-opacity duration-300 mt-20 "
      >
        {/* Text and waitlist */}
        <div className="md:w-1/2">
          {/* Text Area */}
          <div className="flex md:flex-col space-y-10 mb-10 lg:mt-16  xl:mb-8 md:w-full">
            <h2 className="text-4xl font-bold text-center lg:text-5xl  md:text-left ">
              Manage your projects efficiently and effectively, anywhere in the
              world
            </h2>
          </div>

          {/* Waitlist */}
          <div className="">
            <p className="text-xl text-center text-gray-900 lg:max-w-md lg:text-left md:my-6">
              Let us be your ears on the ground
            </p>
            <div className="flex flex-col my-2 md:flex-row">
              <input
                placeholder="Your email"
                className="border border-black px-8 py-4 w-full "
              />
              <button className="mx-auto lg:mx-0 px-8 md:px-0 md:my-0 py-4 font-bold text-white border-black border bg-black w-full my-4">
                Join Waitlist
              </button>
            </div>
            <p className="text-xl text-center text-gray-600  md:text-left my-2 md:w-full">
              Join the wait-list for our cutting-edge project management
              platform today and be among the first to experience the power of
              streamlined collaboration and optimized project workflows.
            </p>
          </div>
        </div>

        {/* Image */}
        <div className="mx-auto mt-10 md:w-1/2">
          <img src={HeroImage} alt="" />
        </div>

        {/* Brands */}
      </section>
    </div>
  )
}

export default Navbar
