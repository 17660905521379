import React from "react"
import TalkIcon from "../assets/Featured icon.svg"
import Collab from "../assets/collab.svg"
import Success from "../assets/succeed.svg"
import Content from "../assets/Content.svg"

const Mission = () => {
  return (
    <div className="relative container mx-auto p-6 md:p-20 " id="about">
      {/* Title */}
      <div className=" text-center mt-6 font-bold">
        <p className="text-lg">WHAT WE DO</p>
        <h2 className="text-4xl my-4 tracking-wide">
          Easy Management Platform to help You Manage your Projects
        </h2>
      </div>

      <div className="md:flex md:flex-row">
        <div className="md:w-1/2 md:pb-4">
          {/* Communicate */}
          <div className="mt-20">
            <img className="my-4 md:my-1" src={TalkIcon} alt="" />
            <div className="flex flex-col p-2">
              <h2 className=" text-2xl">Communicate</h2>
              <p className=" text-gray-500 mt-4 md:text-lg">
                In-app messaging feature lets you easily contact your Project
                Manager or admin for easy communication of ideas.
              </p>
            </div>
          </div>

          {/* Collaborate */}
          <div className="mt-20 md:mt-6">
            <img className="my-4 md:my-1" src={Collab} alt="" />
            <div className="flex flex-col p-2">
              <h2 className=" text-2xl">Collaborate</h2>
              <p className="text-md text-gray-500 mt-4 md:text-lg">
                Option to upload reports for easy collaboration and tracking of
                project progress.
              </p>
            </div>
          </div>

          {/* Succeed */}
          <div className="mt-20 md:mt-6">
            <img className="my-4 md:my-1" src={Success} alt="" />
            <div className="flex flex-col p-2">
              <h2 className=" text-2xl">Succeed</h2>
              <p className=" text-gray-500 mt-4 md:text-lg">
                Verification and approval of tasks by a qualified team and also
                you the user to ensure 100% integrity.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-20 md:w-1/2">
          <img
            src={Content}
            alt=""
            className="object-contain md:w-full w-full"
          />
        </div>
      </div>
    </div>
  )
}

export default Mission
